@import '/src/Common/mixins.scss';

.dialog {
    margin: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 30px);
    padding: 15px 15px;

    @include responsive(desktop) {
        width: calc(100% - 40px);
        padding: 20px 20px;
        min-width: 600px;
    }
}

.title {
    white-space: nowrap;
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
    color: #2b2551;
}

.description {
    font-family: 'Poppins';
    font-weight: 500;
    color: #2b2551;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    @include responsive(tablet) {
        font-size: 18px;
    }

    @include responsive(desktop) {
        margin-top: 20px;
        font-size: 18px;
    }
}

.checkbox {
    width: 100%;
    margin-bottom: 15px;
    padding-left: 15px;
    max-width: 320px;

    @include responsive(tablet) {
        max-width: 450px;
    }

    @include responsive(desktop) {
        max-width: 600px;
    }
}

.icon {
    cursor: pointer;
    align-self: flex-end;
}

.signedByContainer {
    margin: 5px 0px;
    width: 100%;

    @include responsive(tablet) {
        margin: 10px 0px;
    }

    @include responsive(desktop) {
        margin: 15px 0px;
        padding-left: 15px;
    }
}

.signedByInputContainer {
    width: 100%;
    align-self: center;
    margin: 0px 0px;

    @include responsive(desktop) {
        max-width: 500px;
    }
}

.signedByInput {
    width: 100%;
    max-width: 250px;
}

.label {
    @include responsive(desktop) {
        width: 100px;
    }
}
