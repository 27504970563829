@import '/src/Common/mixins.scss';

.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 25px;
}

.selectedComponentContainer {
    height: 100%;
}

.container {
    display: flex;
    align-items: center;
    gap: 20px;

    width: calc(100% - 30px);
    padding: 0px 15px;

    @include responsive(tablet) {
        width: calc(100% - 40px);
        padding: 0px 20px;
        gap: 60px;
    }

    @include responsive(desktop) {
        width: 100%;
        padding: 0;
        gap: 60px;
    }
}

.item,
.selectedItem {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 18px;
    color: #2b2551;
    cursor: pointer;

    @include responsive(tablet) {
        font-size: 22px;
    }

    @include responsive(desktop) {
        font-size: 24px;
    }
}

.item {
    opacity: 0.5;
}
