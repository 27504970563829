@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 20px;
}

.separator {
    height: 0px;
    width: 100%;
    opacity: 0.5;
    border: 1px solid #6d6d6d;
}

.logo {
    width: 134px;
    height: 34px;
    @include responsive(tablet) {
        width: 165px;
        height: 42px;
    }
    @include responsive(desktop) {
        width: 188px;
        height: 48px;
    }
}
