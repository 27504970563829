@import '/src/Common/mixins.scss';

.container,
.disabledContainer {
    font-family: 'Lora';
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #0b5394;
    border-radius: 10px;
    padding: 11px 0px;
    min-width: 148px;
    text-align: center;
    @include responsive(tablet) {
        min-width: 180px;
        padding: 13px 0px;
    }
    @include responsive(desktop) {
        font-size: 18px;
        min-width: 240px;
        padding: 15px 0px;
    }
}

.container {
    cursor: pointer;
}

.disabledContainer {
    opacity: 0.5;
    user-select: none;
    cursor: not-allowed;
}
