@import '/src/Common/mixins.scss';

.scrollIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: fixed;

    right: 20px;
    bottom: 20px;
    @include responsive(tablet) {
        right: 50px;
        bottom: 50px;
    }

    background-color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
}
