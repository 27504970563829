@import '/src/Common/mixins.scss';

.select,
.selectWithError {
    border-radius: 10px !important;
    max-width: 270px !important;
    min-width: 270px !important;
    outline: none;
    text-align: left;
    cursor: pointer;
    font-size: 14px !important;

    @include responsive(tablet) {
        font-size: 16px !important;
        max-width: 380px !important;
        min-width: 300px !important;
    }

    @include responsive(desktop) {
        font-size: 18px !important;
        max-width: 380px !important;
        min-width: 300px !important;
    }
}

.select {
    border: 1px solid rgba(109, 109, 109, 0.5);
}

.selectWithError {
    border: 1px solid #b90000;
}

.icon {
    margin-right: 20px;
}

.title {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 18px;
    color: #213159;
    padding-left: 20px;
    padding-bottom: 5px;
}

.menuItem {
    font-size: 14px !important;

    @include responsive(tablet) {
        font-size: 16px !important;
    }

    @include responsive(desktop) {
        font-size: 18px !important;
    }
}

.container {
    width: 100%;
}
