@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    background: #f6f6f6;
    padding: 30px 20px;
    gap: 25px;
}

.line {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.groupMemberLine {
    display: flex;
    gap: 45px;
}

.categoryText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    text-align: left;
}

.textHeader {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 18px;
    color: #001940;
}

.feinInput {
    @include responsive(desktop) {
        max-width: 160px;
    }
}

.nameInput {
    @include responsive(desktop) {
        max-width: 550px;
    }
}

.phoneInput {
    @include responsive(desktop) {
        max-width: 550px;
    }
}

.stateInput {
    @include responsive(desktop) {
        max-width: 185px;
    }
}

.addressInput {
    @include responsive(desktop) {
        max-width: 500px;
    }
}

.cityInput {
    @include responsive(desktop) {
        max-width: 330px;
    }
}

.zipInput {
    @include responsive(desktop) {
        max-width: 185px;
    }
}

.dateInput {
    @include responsive(desktop) {
        max-width: 290px;
    }
}
