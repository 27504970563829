.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    gap: 10px;
    border-top: 1px solid #b5b6b7;
}

.text {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #001940;
}
