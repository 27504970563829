@import '/src/Common/mixins.scss';

.container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-top: 1px solid #b5b6b7;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    @include responsive(desktop) {
        flex-direction: row;
    }
}

.counter {
    min-width: 30px;
    width: 30px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    width: 100%;
}

.tabletContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.infoContainer {
    display: flex;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    text-align: left;
    gap: 20px;
    @include responsive(desktop) {
        gap: 45px;
        width: 85%;
    }
}

.errorContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    @include responsive(tablet) {
        justify-content: flex-end;
    }
    @include responsive(desktop) {
        justify-content: flex-end;
    }
}

.notTabletContainer {
    margin-left: 50px;
    @include responsive(tablet) {
        display: none;
    }
}

.select {
    max-width: 300px;
}
