@import '/src/Common/mixins.scss';

.logoIcon {
    width: 134px;
    height: 34px;
    @include responsive(tablet) {
        width: 165px;
        height: 42px;
    }
    @include responsive(desktop) {
        width: 188px;
        height: 48px;
    }
}
