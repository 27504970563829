@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    padding: 0px 15px;

    @include responsive(tablet) {
        width: calc(100% - 40px);
        padding: 0px 20px;
    }

    @include responsive(desktop) {
        width: 100%;
        padding: 0;
    }
}
