@import '/src/Common/mixins.scss';

.pageContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    @include responsive(desktop) {
        padding-left: 10%;
        padding-right: 10%;
        width: calc(100% - 20%);
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 20px;
}

.separator {
    height: 0px;
    width: 100%;
    opacity: 0.5;
    border: 1px solid #6d6d6d;
}

.formHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.formHeaderActions {
    display: flex;
    align-items: center;
    gap: 50px;
}

.formTitleContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.backIcon {
    cursor: pointer;
}

.formTitle {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
    color: #2b2551;
    user-select: none;
}

.formStatusContainer {
    margin-top: 30px;
}

.printIcon {
    cursor: pointer;
}

.saveContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}
