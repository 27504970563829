@import '/src/Common/mixins.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #b5b6b7;
    padding-top: 20px;
    padding-bottom: 20px;
    @include responsive(desktop) {
        flex-direction: row;
        align-items: flex-start;
    }
}

.counter {
    min-width: 30px;
}

.infoContainer {
    display: flex;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    width: 100%;
    text-align: left;
    gap: 20px;
    @include responsive(desktop) {
        justify-content: space-between;
        gap: 45px;
    }
}

.formLine {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    @include responsive(desktop) {
        flex-direction: row;
    }
}
