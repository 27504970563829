@import '/src/Common/mixins.scss';

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 57px;
    @include responsive(tablet) {
        padding-top: 79px;
    }
    @include responsive(desktop) {
        padding-top: 0px;
    }
}

.formTitle {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
    color: #2b2551;
}

.formSubtitle {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    color: #213159;
    margin-top: 5px;
    @include responsive(desktop) {
        font-size: 18px;
    }
}

.inputsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.resendLink {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    margin-top: 20px;
    color: #0b5394;
    cursor: pointer;
}

.verificationCodeInput {
    width: 100%;
    font-size: 18px;

    @include responsive(tablet) {
        font-size: 24px;
        width: 400px;
    }
    @include responsive(desktop) {
        font-size: 24px;
    }
}
