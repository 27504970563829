@import '/src/Common/mixins.scss';

.dialog {
    margin: 16px !important;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 30px);

    padding: 15px 15px 30px;

    @include responsive(desktop) {
        width: calc(100% - 40px);
        padding: 20px 20px 40px;
        min-width: 600px;
        gap: 10px;
    }
}

.title {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
    color: #001940;
    margin-bottom: 20px;
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    width: 100%;

    @include responsive(desktop) {
        width: initial;
    }
}

.fieldLine {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @include responsive(desktop) {
        width: 100%;
        flex-direction: row;
    }
}

.companyIcon {
    width: 100px;
    height: 100px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.closeIcon {
    align-self: flex-end;
    cursor: pointer;
}

.buttonContainer {
    margin-top: 10px;
}

.bigInput {
    width: 100%;
    @include responsive(desktop) {
        width: 450px;
    }
}

.averageInput {
    width: 100%;
    @include responsive(desktop) {
        width: 285px;
    }
}

.smallInput {
    width: 100%;
    @include responsive(desktop) {
        width: 100px;
    }
}
