@import '/src/Common/mixins.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
  height: 100%;

  @include responsive(desktop) {
    width: 100%;
  }
}

.headerRow {
  @include responsive(desktop) {
    border: 1px solid rgba(109, 109, 109, 0.5);
  }
  height: 75px;
  border-radius: 10px;
}

.tableHead {
  background-color: white;
  padding: 3px;
  height: 100%;
}

.tableRow {
  background-color: white;
  cursor: pointer;
  height: 80px;

  @include responsive(desktop) {
    background-color: transparent;
  }

  &:nth-child(odd) {
    background-color: white;
  }

  &:nth-child(even) {
    background-color: #f6f6f6;
  }
}

.tableRow:hover {
  background-color: #dcedfd;
}

.cell,
.headerCell {
  font-family: 'Poppins';
  font-weight: 500;
  user-select: none;
  padding: 8px 12px;

  @include responsive(tablet) {
    padding: 10px 16px;
  }

  @include responsive(desktop) {
    padding: 10px 16px;
  }
}

.cell {
  font-size: 14px;
  color: #001940;

  &:first-child {
    position: -webkit-sticky; // for safari
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: white;

    -webkit-box-shadow: -1px 0px 1px 0px rgba(109, 109, 109, 0.5) inset;
    -moz-box-shadow: -1px 0px 1px 0px rgba(109, 109, 109, 0.5) inset;
    box-shadow: -1px 0px 1px 0px rgba(109, 109, 109, 0.5) inset;

    @include responsive(desktop) {
      background-color: transparent;
      box-shadow: none;
    }
  }

  @include responsive(tablet) {
    font-size: 16px;
  }

  @include responsive(desktop) {
    font-size: 16px;
  }
}

.headerCell {
  font-size: 14px;
  color: #0b5394;

  position: -webkit-sticky; // for safari
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;

  &:first-child {
    z-index: 3;
  }

  -webkit-box-shadow: -1px -2px 1px 0px rgba(109, 109, 109, 0.5) inset,
  0px 1px 1px 0px rgba(109, 109, 109, 0.5) inset,
  0px -2px 1px 0px rgba(109, 109, 109, 0.5) inset;
  -moz-box-shadow: -1px -2px 1px 0px rgba(109, 109, 109, 0.5) inset,
  0px 1px 1px 0px rgba(109, 109, 109, 0.5) inset,
  0px -2px 1px 0px rgba(109, 109, 109, 0.5) inset;
  box-shadow: -1px 0px 1px 0px rgba(109, 109, 109, 0.5) inset,
  0px 1px 1px 0px rgba(109, 109, 109, 0.5) inset,
  0px -2px 1px 0px rgba(109, 109, 109, 0.5) inset;

  @include responsive(tablet) {
    font-size: 16px;
  }

  @include responsive(desktop) {
    font-size: 18px;
    box-shadow: none;
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.actionIcon {
  width: 32px;
  height: 32px;
}

.actionIcon:hover {
  background: #efefef;
}

.tableContainer {
  height: calc(100vh - 300px);

  @include responsive(tablet) {
    height: calc(100vh - 300px);
  }

  @include responsive(desktop) {
    height: calc(100vh - 350px);
  }
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
}

.noResultsFound {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 400;
  color: #b5b6b7;

  @include responsive(desktop) {
    height: 100%;
    padding-top: 0;
  }
}

.table {
  border-collapse: collapse;
  table-layout: fixed;
  @include responsive(desktop) {
    width: 100%;
  }

  @include responsive(tablet) {
    width: 100%;
  }

}

.cell:first-child {
  .tableRow:nth-child(odd) & {
    background-color: #ffffff;

    @include responsive(desktop) {
      background-color: transparent;
    }
  }

  .tableRow:nth-child(even) & {
    background-color: #f6f6f6;

    @include responsive(desktop) {
      background-color: transparent;
    }
  }
}
