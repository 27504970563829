.icon {
    margin-left: 10px;
    width: 20px;
    height: 22px;
}

.root {
    flex-direction: row-reverse;
}

.input {
    cursor: pointer;
    border: none;
}

.container {
    border-color: #b5b6b7;
}

.errorContainer {
    border-color: #b90000;
}

.disabledContainer {
    border-color: #656565;
}

.container:focus-within,
.errorContainer:focus-within,
.disabledContainer :focus-within {
    background: #dcedfd;
    outline: none;
}

.container,
.errorContainer,
.disabledContainer {
    border-radius: 10px !important;
    width: fit-content;
    border-width: 1px;
    border-style: solid;
}

.commonContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

.labelContainer {
    display: flex;
    flex-direction: column;
}

.inputLabel {
    font-family: 'Lora';
    font-weight: 600;
    color: #001940;
    padding-left: 20px;
    padding-bottom: 5px;
}
