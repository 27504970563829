@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 1px solid #b5b6b7;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 10px;
}

.counter {
    min-width: 30px;
}

.infoContainer {
    display: flex;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    text-align: left;
    gap: 20px;

    @include responsive(desktop) {
        gap: 45px;
        width: 85%;
    }
}

.optionsContainer {
    display: flex;
    width: 100%;
    padding-right: 20px;

    @include responsive(tablet) {
        padding-left: 50px;
        width: calc(100% - 70px);
        padding-right: 20px;
    }

    @include responsive(desktop) {
        padding-left: 75px;
        padding-right: 0px;
        width: calc(100% - 75px);
        align-items: center;
    }
}

.radioLabel {
    text-align: left;
}

.radioGroup {
    width: 100% !important;
    padding-left: 50px;
    gap: 10px;

    @include responsive(tablet) {
        padding-left: 0px;
        gap: 0px;
    }

    @include responsive(desktop) {
        padding-left: 0px;
        gap: 0px;
    }
}
