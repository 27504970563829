@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #b5b6b7;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 10px;
}

.infoContainer {
    display: flex;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;

    width: 100%;
    text-align: left;

    gap: 20px;
    @include responsive(desktop) {
        gap: 45px;
        width: 85%;
    }
}

.counter {
    min-width: 30px;
}

.line {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include responsive(desktop) {
        align-items: center;
        flex-direction: row;
    }
}

.textLine {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 50px;
    width: calc(100% - 50px);
    @include responsive(tablet) {
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    @include responsive(desktop) {
        align-items: center;
        padding-left: 75px;
        flex-direction: row;
    }
}

.datePicker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    @include responsive(desktop) {
        flex-direction: row;
        align-items: center;
    }
}

.datePickerLabel {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    color: #2b2551;
    text-align: left;
}

.radioLine {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 50px;

    @include responsive(desktop) {
        padding-left: 75px;
    }
}

.radioLineHeader {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    align-self: flex-start;
}

.radioGroup {
    display: flex;
    align-items: center;
}

.radioQuestion {
    padding-left: 50px;

    @include responsive(desktop) {
        padding-left: 0px;
    }
}
