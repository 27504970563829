.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 20px;
}

.separator {
    height: 0px;
    width: 100%;
    opacity: 0.5;
    border: 1px solid #6d6d6d;
}

.companyIcon {
    cursor: pointer;
}

.menuText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
}

.itemIcon {
    min-width: 36px;
    flex-shrink: 0;
    display: inline-flex;
}

.logoutIcon {
    color: #2b2551;
}
