@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 28px;
    color: #2b2551;
}

.headerTitle {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 20px;
    color: #001940;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
}

.separator {
    height: 0px;
    width: 100%;
    opacity: 0.5;
    border: 1px solid #6d6d6d;
}

.formTitle {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
    color: #2b2551;
}

.fillContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 38px;
    margin-bottom: 32px;
}

.submitButton {
    display: flex;
    align-self: center;
    align-items: center;
    gap: 10px;
    margin: 45px 0px;
}

.defaultContainer,
.editableContainer {
    padding-top: 10px;
    display: flex;
    flex-direction: column;

    @include responsive(desktop) {
        padding-left: 5px;
    }
}

.editableContainer {
    //background-color: #dcedfd;
}

.groupMemberLine {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    gap: 50px;
}

.groupSelect {
    width: 200px;
}

.categoryText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    width: 80%;
    color: #2b2551;
    text-align: left;
}
