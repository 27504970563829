@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #b5b6b7;
    padding-top: 20px;
    padding-bottom: 20px;
}

.infoContainer {
    display: flex;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    text-align: left;

    gap: 20px;
    @include responsive(desktop) {
        gap: 45px;
        width: 85%;
    }
}

.counter {
    min-width: 30px;
}

.line {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include responsive(desktop) {
        flex-direction: row;
    }
}

.textLine {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    @include responsive(tablet) {
        align-items: center;
        padding-left: 50px;
        width: calc(100% - 50px);
        gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;
    }
    @include responsive(desktop) {
        align-items: center;
        padding-left: 75px;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.radioGroup {
    padding-left: 50px;

    @include responsive(desktop) {
        padding-left: 0px;
    }
}

.label {
    @include responsive(desktop) {
        width: 300px;
    }
}
