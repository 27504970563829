@import '/src/Common/mixins.scss';

.notificationContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    padding-left: 20px;
    width: calc(100% - 30px);
    min-height: 70px;

    @include responsive(tablet) {
        gap: 30px;
    }
    @include responsive(desktop) {
        width: initial;
        gap: 30px;
        max-width: 720px;
    }
}

.notificationBody {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    color: #001940;
    max-width: 75vw;
    height: 100%;
    @include responsive(tablet) {
        max-width: 90%;
    }
    @include responsive(desktop) {
        max-width: 90%;
    }
}

.notificationIcon {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
}
