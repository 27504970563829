@import '/src/Common/mixins.scss';

.container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    border-top: 1px solid #b5b6b7;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 10px;
    @include responsive(tablet) {
        flex-direction: row;
        gap: 0px;
    }
    @include responsive(desktop) {
        flex-direction: row;
        gap: 0px;
    }
}

.counter {
    min-width: 30px;
}

.infoContainer {
    display: flex;
    gap: 20px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    text-align: left;
    @include responsive(tablet) {
        width: 100%;
    }
    @include responsive(desktop) {
        gap: 45px;
        width: 85%;
    }
}

.input {
    width: 100%;
    max-width: 200px;
    min-width: 100px;
    @include responsive(tablet) {
        max-width: 100%;
    }
    @include responsive(desktop) {
        max-width: 100%;
    }
}

.mobileInputContainer {
    padding-left: 50px;
    width: calc(100% - 50px);
    max-width: 180px;
    @include responsive(tablet) {
        padding-left: 0px;
        width: 100%;
    }
    @include responsive(desktop) {
        width: 100%;
        padding-left: 0px;
    }
}
