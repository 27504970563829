@import '/src/Common/mixins.scss';

.container {
    display: flex;
    border-top: 1px solid #b5b6b7;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    gap: 20px;

    @include responsive(desktop) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.title {
    margin-bottom: 10px;
    align-self: flex-start;
    text-align: left;
    @include responsive(desktop) {
        margin-bottom: 30px;
    }
}

.infoContainer {
    display: flex;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    text-align: left;
    gap: 20px;
    @include responsive(desktop) {
        justify-content: space-between;
        gap: 45px;
    }
}

.counter {
    min-width: 30px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.listItem {
    display: flex;
    gap: 5px;
    text-align: left;
    @include responsive(desktop) {
        align-items: flex-start;
    }
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include responsive(desktop) {
        padding-left: 0px;
        display: block;
    }
}
