@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: 100%;
    max-height: calc(100vh - 15px);
    min-height: calc(100vh - 15px);

    @include responsive(tablet) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        max-height: calc(100vh - 20px);
        min-height: calc(100vh - 20px);
    }
    @include responsive(desktop) {
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 30px;
        max-height: calc(100vh - 30px);
        min-height: calc(100vh - 30px);
    }
}

.contentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
    flex-grow: 2;
    gap: 0px;

    @include responsive(tablet) {
        gap: 15px;
    }

    @include responsive(desktop) {
        flex-direction: row;
        gap: 15px;
    }
}

.mainImage {
    width: 100%;
    height: 100%;
    max-height: 230px;
    margin-top: 15px;
    flex-grow: 2;
    background-position: center;
    background-image: url(../../Images/Mobile/MobileLoginImage.png);
    background-repeat: no-repeat;
    object-fit: cover;
    @include responsive(tablet) {
        margin-top: 0px;
        max-height: 405px;
        background-image: url(../../Images/Tablet/TabletLoginImage.png);
    }
    @include responsive(desktop) {
        max-height: 100%;
        padding-bottom: 0px;
        margin-top: 60px;
        height: 75vh;
        background-image: url(../../Images/Desktop/DesktopLoginImage.png);
    }
}
