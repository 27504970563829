@import '/src/Common/mixins.scss';

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    @include responsive(desktop) {
        width: auto;
    }
}

.leftLabeledInputContainer {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 15px;
}

.leftLabeledInputContainer .inputLabel {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    margin-left: 0px;
}

.inputLabel {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 18px;
    color: #001940;
    margin-left: 16px;
    text-align: left;
}

.inputBorder {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(109, 109, 109, 0.5);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;

    @include responsive(tablet) {
        border-width: 2px;
    }

    @include responsive(desktop) {
        border-width: 2px;
    }
}

.inputBorder:focus-within {
    background-color: #dcedfd !important;
}

.inputBorder .input:focus-within {
    background-color: #dcedfd !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.passwordInput {
    font-family: text-security-disc;
    -webkit-text-security: disc !important;
}

.input,
.passwordInput {
    color: #001940;
    border: 0px solid transparent;
    height: 40px;
    font-size: 24px;
    outline: none;
    background-color: transparent;

    @include responsive(desktop) {
        width: calc(100% - 20px);
    }
}

.passwordContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    @include responsive(desktop) {
        width: 100%;
        justify-content: baseline;
    }
}

.errorContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

.verticalContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.leftLabeledInputContainer .verticalContainer {
    gap: 0px;
}

.underLabel {
    font-family: 'Lora';
    font-size: 20px;
    align-self: flex-start;
    margin-left: 10px;
}

.passwordIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
