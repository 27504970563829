@import '/src/Common/mixins.scss';

.container,
.disabledContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #0b5394;
    border-radius: 10px;
    min-width: 160px;
    min-height: 45px;

    @include responsive(tablet) {
        min-width: 190px;
        min-height: 52px;
    }
    @include responsive(desktop) {
        min-width: 240px;
        min-height: 55px;
    }

    gap: 10px;
}

.container {
    cursor: pointer;
}

.disabledContainer {
    opacity: 0.5;
    user-select: none;
    cursor: not-allowed;
}

.title {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 14px;

    @include responsive(tablet) {
        font-size: 16px;
    }
    @include responsive(desktop) {
        font-size: 18px;
    }
}
