@import '/src/Common/mixins.scss';

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-top: 1px solid #b5b6b7;
	padding-top: 20px;
	padding-bottom: 20px;
}

.infoContainer {
	display: flex;
	flex-direction: column;
	font-family: 'Poppins';
	font-weight: 500;
	font-size: 16px;
	color: #2b2551;
	width: 100%;
	text-align: left;
	gap: 10px;
}

.counter {
	min-width: 30px;
	font-family: 'Poppins';
	font-weight: 500;
	font-size: 16px;
	color: #2b2551;
}

.line {
	display: flex;
	align-items: flex-start;
	gap: 20px;
	width: 100%;
	text-align: left;
	@include responsive(desktop) {
		gap: 45px;
	}
}

.datePickerText {
	font-family: 'Poppins';
	font-weight: 500;
	font-size: 16px;
	color: #2b2551;
}

.dateLine {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	gap: 15px;
	width: 100%;

	@include responsive(desktop) {
		flex-direction: row;
		align-items: center;
	}
}

.formLine {
	display: flex;
	flex-direction: column;
	width: 14%;
	align-items: center;
	margin-top: 2.1rem;
	gap: 10px;
	@include responsive(desktop) {
		flex-direction: column;
	}
}
