@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    height: 100%;
    gap: 10px;
    max-height: calc(100vh - 20px);
    min-height: calc(100vh - 20px);
    @include responsive(tablet) {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        max-height: calc(100vh - 30px);
        min-height: calc(100vh - 30px);
    }
    @include responsive(desktop) {
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 40px;
        max-height: calc(100vh - 40px);
        min-height: calc(100vh - 40px);
    }
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    @include responsive(tablet) {
        margin-top: 40px;
    }
    @include responsive(desktop) {
        margin-top: 75px;
    }
    align-items: flex-start;
    gap: 15px;
    color: #2b2551;
}

.descriptionHeader {
    font-family: 'Lora';
    font-weight: 400;
    font-size: 42px;
    @include responsive(tablet) {
        font-size: 64px;
    }
    @include responsive(desktop) {
        font-size: 86px;
    }
    color: #001940;
}

.descriptionText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    @include responsive(tablet) {
        font-size: 18px;
    }
    @include responsive(desktop) {
        font-size: 20px;
    }
    text-align: left;
}

.consContainer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 30px);
    gap: 20px;
    @include responsive(tablet) {
        gap: 40px;
    }
    @include responsive(desktop) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.mainImage {
    width: 100%;
    height: 100%;
    max-height: 230px;
    flex-grow: 2;
    background-position: center;
    background-image: url(../../Images/Mobile/MobileMainImage.png);
    background-repeat: no-repeat;
    object-fit: cover;
    @include responsive(tablet) {
        max-height: 405px;
        background-image: url(../../Images/Tablet/TabletMainImage.png);
    }
    @include responsive(desktop) {
        max-height: 100%;
        padding-bottom: 0px;
        margin-top: 60px;
        height: 75vh;
        background-image: url(../../Images/Desktop/DesktopMainImage.png);
    }
}

.topContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include responsive(tablet) {
        gap: 65px;
    }
    @include responsive(desktop) {
        gap: 55px;
    }
}

.infoContainer {
    width: 100%;
}
