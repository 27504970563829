@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: 100%;
    max-height: calc(100vh - 15px);
    min-height: calc(100vh - 15px);

    @include responsive(tablet) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        max-height: calc(100vh - 20px);
        min-height: calc(100vh - 20px);
    }
    @include responsive(desktop) {
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 30px;
        max-height: calc(100vh - 30px);
        min-height: calc(100vh - 30px);
    }
}

.contentContainer {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-grow: 2;
    gap: 0px;

    @include responsive(tablet) {
        gap: 15px;
    }

    @include responsive(desktop) {
        flex-direction: row;
        gap: 15px;
    }
}

.mainImage {
    margin-top: 15px;
    width: 100%;
    height: 100%;
    max-height: 230px;
    flex-grow: 2;
    background-position: center;
    background-image: url(../../Images/Mobile/MobileLoginImage.png);
    background-repeat: no-repeat;
    object-fit: cover;
    @include responsive(tablet) {
        margin-top: 0px;
        max-height: 405px;
        background-image: url(../../Images/Tablet/TabletLoginImage.png);
    }
    @include responsive(desktop) {
        max-height: 100%;
        padding-bottom: 0px;
        margin-top: 60px;
        height: 75vh;
        background-image: url(../../Images/Desktop/DesktopLoginImage.png);
    }
}

.loginFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 57px;
    @include responsive(tablet) {
        padding-top: 79px;
    }
    @include responsive(desktop) {
        padding-top: 0px;
    }
}

.loginFormTitle {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
    color: #001940;
}

.loginFormSubtitle {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    color: #001940;
    margin-top: 5px;
    @include responsive(desktop) {
        font-size: 18px;
    }
}

.inputsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    @include responsive(tablet) {
        width: 400px;
    }
    @include responsive(desktop) {
        width: 575px;
    }
}

.forgotPasswordLink {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    color: #001940;
    border-bottom: 1px dashed #555555;
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 25px;

    @include responsive(tablet) {
        margin-bottom: 45px;
    }
    @include responsive(desktop) {
        margin-bottom: 50px;
    }
}

.loginInput,
.passwordInput {
    width: 100%;
    font-size: 18px;

    @include responsive(tablet) {
        font-size: 24px;
    }
    @include responsive(desktop) {
        font-size: 24px;
    }
}
