
@import '/src/Common/mixins.scss';

.dialog {
  margin: 16px !important;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: calc(100% - 30px);
  padding: 15px 15px;

  @include responsive(desktop) {
    width: calc(100% - 40px);
    padding: 20px 20px 40px 20px;
    min-width: 600px;
  }
}

.title {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #001940;
  margin-bottom: 1rem;

  @include responsive(tablet) {
    font-size: 22px;
  }

  @include responsive(desktop) {
    padding: 0px 30px;
    font-size: 24px;
  }
}

.closeIcon {
  align-self: flex-end;
  cursor: pointer;
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  @include responsive(tablet) {
    gap: 20px;
  }

  @include responsive(desktop) {
    gap: 30px;
  }
}
