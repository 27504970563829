@import '/src/Common/mixins.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    user-select: none;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
    min-width: 150px;
    gap: 10px;
    padding-left: 0px;
    margin: 10px 0px;

    min-width: 300px;

    @include responsive(tablet) {
        min-width: 340px;
    }

    @include responsive(desktop) {
        min-width: 380px;
    }
}

.page,
.activePage {
    min-width: 25px;
    min-height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include responsive(tablet) {
        min-width: 30px;
        min-height: 30px;
    }

    @include responsive(desktop) {
        min-width: 35px;
        min-height: 35px;
    }
}

.activePage {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 5px;
    outline: 0;
    border-radius: 5px;
    background: #0b5394;
    color: #ffffff;
}

.link {
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 20px;

    @include responsive(tablet) {
        width: 30px;
        height: 30px;
        font-size: 24px;
    }

    @include responsive(desktop) {
        width: 35px;
        height: 35px;
    }
}

.next,
.previous {
    font-size: 35px;
    @include responsive(tablet) {
        font-size: 40px;
    }

    @include responsive(desktop) {
        font-size: 45px;
    }

    cursor: pointer;
    color: #b5b6b7;
}

.next:hover,
.previous:hover {
    color: #0b5394;
}
