@import '/src/Common/mixins.scss';

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f6f6f6;
    padding: 10px 10px;
    gap: 10px;

    @include responsive(tablet) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }

    @include responsive(desktop) {
        flex-direction: row;
        padding: 25px 35px;
        gap: 30px;
    }
}

.formColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    @include responsive(tablet) {
        width: 45%;
    }

    @include responsive(desktop) {
        gap: 20px;
    }
}

.formItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.formValueLabel {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    color: #0b5394;
}

.formValue {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
}
