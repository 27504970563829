@import '/src/Common/mixins.scss';

.dialog {
  margin: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 30px);

  padding: 15px 15px 30px;

  @include responsive(desktop) {
    width: calc(100% - 40px);
    padding: 20px 20px 40px;
    min-width: 600px;
  }
}

.title {
  font-family: 'Lora';
  font-weight: 600;
  font-size: 24px;
  color: #2b2551;
  margin-bottom: 20px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
  margin-bottom: 15px;

  @include responsive(desktop) {
    width: 400px;
    gap: 15px;
  }
}

.closeIcon {
  align-self: flex-end;
  cursor: pointer;
}

.buttonContainer {
  margin-top: 10px;
}

.emailInput {
  width: 100%;
}

.passwordInput {
  width: 100%;
}
