.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
}

.certified,
.pending,
.denied,
.waiting {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    user-select: none;
}

.certified {
    color: #55b959;
}

.pending {
    color: #2b2551;
}

.denied {
    color: #e13535;
}

.waiting {
    color: #f3a100;
}
