.select {
    border-radius: 50px !important;
    width: 100%;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #0b5394;
}

.text {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #2b2551;
}
