@import '/src/Common/mixins.scss';

.dialog {
    margin: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 30px);
    gap: 10px;

    padding: 15px 15px;

    @include responsive(desktop) {
        width: calc(100% - 40px);
        padding: 20px 20px;
        min-width: 600px;
        gap: 20px;
    }
}

.title {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
}

.text {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    width: 85%;

    @include responsive(tablet) {
        width: 350px;
    }

    @include responsive(desktop) {
        width: 400px;
    }
}

.closeIcon {
    cursor: pointer;
    align-self: flex-end;
}
