@import '/src/Common/mixins.scss';

.pageContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 20px;
}

.separator {
    height: 0px;
    width: 100%;
    opacity: 0.5;
    border: 1px solid #6d6d6d;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 100%;

    @include responsive(tablet) {
        gap: 30px;
    }

    @include responsive(desktop) {
        gap: 40px;
    }
}

.contentIcon {
    width: 100px;
    height: 100px;

    @include responsive(tablet) {
        width: 120px;
        height: 120px;
    }

    @include responsive(desktop) {
        width: 155px;
        height: 155px;
    }
}

.contentText {
    font-family: 'Lora';
    font-weight: 600;
    color: #2b2551;
    user-select: none;
    font-size: 20px;
    @include responsive(tablet) {
        font-size: 24px;
        width: 400px;
    }
    @include responsive(desktop) {
        font-size: 36px;
        width: 750px;
    }
}
