@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 30px 20px;
    gap: 25px;
}

.line {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
}

.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: #001940;
}

.headerText {
    max-width: 90%;
}

.error {
    color: red;
}

.nameContainer {
    width: 100%;
    @include responsive(tablet) {
        flex-basis: 2;
    }
    @include responsive(desktop) {
        width: 500px;
    }
}

.ssnContainer {
    width: 100%;
    @include responsive(desktop) {
        width: 270px;
    }
}

.addressContainer {
    width: 100%;
    @include responsive(desktop) {
        width: 500px;
    }
}

.cityContainer {
    width: 100%;
    @include responsive(desktop) {
        width: 330px;
    }
}

.stateContainer {
    width: 100%;
    @include responsive(desktop) {
        width: 185px;
    }
}

.zipContainer {
    width: 100%;
    @include responsive(desktop) {
        width: 185px;
    }
}
