@import '/src/Common/mixins.scss';

.dialog {
    margin: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 30px);

    padding: 15px 15px;

    @include responsive(desktop) {
        width: calc(100% - 40px);
        padding: 20px 20px;
        min-width: 600px;
        gap: 10px;
    }
}

.title {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
    color: #2b2551;
}

.inputsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 0px;
}

.icon {
    cursor: pointer;
    align-self: flex-end;
}

.passwordInput {
    width: 100%;

    @include responsive(tablet) {
        min-width: 270px;
    }

    @include responsive(desktop) {
        min-width: 450px;
    }
}
