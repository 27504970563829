@import '/src/Common/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    @include responsive(desktop) {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.title {
    font-family: 'Lora';
    font-weight: 600;
    font-size: 24px;
    color: #2b2551;
    align-self: flex-start;
    margin-top: 25px;
    margin-bottom: 25px;
}

.actionComponents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    width: calc(100% - 30px);
    padding: 0px 15px;
    margin-bottom: 10px;
    gap: 10px;

    @include responsive(tablet) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        width: calc(100% - 40px);
        padding: 0px 20px;
        gap: 10px;
    }

    @include responsive(desktop) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 100%;
        padding: 0;
        gap: 10px;
    }
}

.csvIcon {
    width: 20px;
    height: 20px;
}

.content {
    width: 100%;
    height: calc(100vh - 150px);

    @include responsive(desktop) {
        height: 100%;
    }
}

.buttonsContainer {
    display: flex;
    align-items: center;
    gap: 15px;
}

.searchContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: calc(100% - 80px);
    width: 100%;
}

.searchInput {
    width: 100%;
    height: 45px;

    @include responsive(tablet) {
        min-width: 380px;
    }

    @include responsive(desktop) {
        width: 470px;
    }
}
