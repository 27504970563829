.container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2551;
    border-radius: 50px;
    min-height: 50px;
    gap: 25px;
    cursor: pointer;
}

.container:hover {
    color: #0b5394;
}

.title {
    font-family: 'Lora';
    font-weight: 500;
    font-size: 18px;
}
