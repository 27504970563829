$MAX_MOBILE_WIDTH: 420px;
// $MAX_MOBILE_WIDTH: 375px;
$MAX_TABLET_WIDTH: 1024px;
// $MIN_TABLET_WIDTH: 376px;
$MIN_TABLET_WIDTH: 421px;
$MIN_DESKTOP_WIDTH: 1025px;

@mixin responsive($breakpoint) {
    @if $breakpoint==mobile {
        @media (max-width: $MAX_MOBILE_WIDTH) {
            @content;
        }
    }

    @if $breakpoint==tablet {
        @media (min-width: $MIN_TABLET_WIDTH) and (max-width: $MAX_TABLET_WIDTH) {
            @content;
        }
    }

    @if $breakpoint==desktop {
        @media (min-width: $MIN_DESKTOP_WIDTH) {
            @content;
        }
    }
}
