@import '/src/Common/mixins.scss';

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.inputBorder {
    border: 2px solid rgba(109, 109, 109, 0.5);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    @include responsive(tablet) {
        max-width: 240px;
    }

    @include responsive(desktop) {
        max-width: 480px;
    }
}

.inputBorder:focus-within {
    background: #dcedfd;
}

.input {
    color: #213159;
    width: calc(100% - 40px);
    border: 0px solid transparent;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    @include responsive(tablet) {
        font-size: 14px;
    }
    outline: none;
    background-color: transparent;
}

.icon {
    min-width: 36px;
    height: 36px;
}
