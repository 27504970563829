@import '/src/Common/mixins.scss';

.borderContainer,
.container {
    display: flex;
    align-items: center;
    gap: 20px;
}

.borderContainer {
    border: 1px solid #d9d9d9;
    border-radius: 500px;
}

.consText {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    @include responsive(tablet) {
        font-size: 18px;
    }
    @include responsive(desktop) {
        font-size: 18px;
    }
    color: #2b2551;
}

.checkIcon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
}
