@import '/src/Common/mixins.scss';

.label {
	color: #2b2551 !important;
}

.container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.group {
	flex-wrap: nowrap !important;
}
